@import '../root/globals.scss';

.sff-input-group {
  display: flex;
  width: 100%;
  align-items: center;

  .input-group-addon {
    display: table-cell;
    width: 3rem;
    background-color: #eee;
    text-align: center;
    vertical-align: middle;

    box-sizing: border-box;
    border: 1px solid $input-border;
  }

  &.nofix {
    width: 100%;
  }

  &.prefix, &.presuffix {
    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .input-group-addon.prefix {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-right: 0;
      display: flex;
      align-self: stretch;
      align-items: center;
      justify-content: center;
    }
  }

  &.suffix, &.presuffix {
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .input-group-addon.suffix {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-left: 0;
      display: flex;
      align-self: stretch;
      align-items: center;
      justify-content: center;
    }
  }

  input {
    &:disabled {
      background: #f2f2f3;
      border-color: #e6e6e6;

      &::-webkit-input-placeholder {
        color: hsl(0,0%,60%);
        opacity: 1;
      }
      &::-moz-input-placeholder {
        color: hsl(0,0%,60%);
        opacity: 1;
      }
      &::-ms-input-placeholder {
        color: hsl(0,0%,60%);
        opacity: 1;
      }
      &::placeholder {
        color: hsl(0,0%,60%);
        opacity: 1;
      }
    }
  }

}
