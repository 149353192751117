@import '../root/globals.scss';
@import './Pane.scss';

.generic-show {
  .controls {
    display: flex;
    justify-content: space-between;

    h1 {
      margin-bottom: 0.8em;
    }
  }

  .form {
    .section {
      margin-top: 1em;
      margin-bottom: 2em;
    }

    .section.scrollable {
      overflow-x: scroll;
    }

    .section {
      background-color: $white;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      padding: 0.5em;
      @include pane;

      h4 {
        margin: 1em;
      }

      .toggles {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-left: 1em;
        margin-right: 1em;
      }

      .fields {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 60em;
        width: 100%;
        padding-top: 0.6em;

        .three-col {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .field {
            width: 29%;
            max-width: 18em;
            min-width: 14em; /* min for door delivery select on create quote */
          }
        }

        .field {
          width: 46%;
          min-width: 20em;
        }

        .field.double-width {
          width: 100%;

          .sff-input-group {
            width: 100%;
          }
        }
      }
    }

    .controls {
      // float: right;

      button:first-of-type { margin-left: 0; }
      button:last-of-type { margin-right: 0; }
    }
  .step-controls {
    button:first-of-type { margin-left: 0; }
    button:last-of-type { margin-right: 0; }
  }
  }
}
