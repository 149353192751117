$grey: lighten(#212121, 30%);
$gray: $grey;
$light-grey: #C6C8CA;
$error: #FF3A64;
$danger: crimson;
$success: #427AA1;
$black: #212121;
$black-10:lighten(#212121, 80%);
$text-black: #212121;
$white: #ffffff;
$ghost-white: #F8F9FD;
$primary-color: #46A847;
$menu-highlight: #ECF6EC;
$faded-white: #f5f5f5;
$light-border:#EFF4F5;
$input-border: #D6D6D6;
