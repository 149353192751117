@import '../../root/globals.scss';


div.toggle {

  &.inline-labelled {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin: 0 1em 1em 1em;
    
    label {
      white-space: nowrap;
    }

    div.react-toggle {
      margin-left: 1em;
    }
  }

}
