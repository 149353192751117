@import '../../root/globals.scss';

$unit: hsla(120, 33, 97, 1);
$unit-border: hsla(0,0,0,0.19);
$unit-hover: peachpuff;


.planner-unit-block {
  font-size: 0.8em;
  padding-bottom: 0.8em;

  .unit-icon {
    width: 55px;
    max-width: 55px;
  }

  .headings, .planner-unit, .footings {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 1.8em;

      &.flex-half { flex: 0.5 };
      &.flex-2 { flex: 2 };
      &.unit-icon {
        width: 55px;
        max-width: 55px;
      }
    }
  }

  .footings {
    font-size: 0.9em;
    padding: 0.3em 0 0.3em 0;
  }

  .units {

    .headings {
      border-bottom: 1px solid $input-border;
      font-weight: 500;
      background-color: $white;
    }

    .planner-unit {
      background-color: $unit;
      border-bottom: 1px solid $input-border;
      
      span {
        line-height: 3em;

        a {
          color: $text-black;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
      cursor: grab;

      &:hover {
        background-color: $unit-hover;
      }

      span.priority {
        span.high-priority {
          color: $danger;
          font-weight: 600;
        }
      }
    }
  }
}
