@import '../../root/globals.scss';


div.floating-alert-stream {
  position: absolute;
  top: 100%;
  z-index: 11;
}

div.floating-alert {
  background-color: #4c4c4c;
  margin: 0;
  padding: 0 1em;
  width: 381px;
  color: $white;

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto !important;

  opacity: 0;
  transition: all 0.3s ease-in-out;

  &.visible {
    opacity: 1;
  }

  div {
    height: auto !important;
    display: flex;
    flex-direction: column;

    padding: 1em 0;
    font-size: 0.9rem;

    a.suggestion {
      text-decoration: none;
      color: $white;

      &:visited {
        text-decoration: none;
      }
    }
  }

  .close-alert {
    margin-left: 1em;
    font-weight: 600;
    padding: 0.85em 0 1em 0;

    cursor: pointer;
  }
}
