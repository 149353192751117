@import '../../root/globals.scss';
@import '../../generic/show/general.scss';
@import '../../generic/show/nested-tabs.scss';

.measurements-tab {
  @include general;
  @include nested-tabs;

  .section.top {
    display: block;
    min-height: 18em;
    .form-fields {
      flex-wrap: wrap;
    }

    .form-status.error {
      margin-bottom: 0;
    }
  }

  .section {
    padding-bottom: 2em;
    border-bottom: 1px solid #F8F9FD;

    &.controls {
      border-bottom: 0;
    }

    &.measurement-sets {
      margin-left: 1em;
      margin-right: 1em;

      h4 {
        margin-left: 0;
        margin-top: 0;
        padding-top: 0;
      }
    }
  }

  div.form-fields {
    display: flex;
    flex-direction: row;

    div.field {
      flex: 1;
    }
  }

}
