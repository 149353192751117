// fixed dimensions
$topbar-height: 70px;
$navbar-collapsed-width: 70px;
$navbar-expanded-width: 290px;
$navbar-group-height: 60px;
$navbar-subgroup-height: 60px;

/*
 * colors - import a from a theme scss file.
 * the theme file should specify the following variables:
 * $grey, $light-grey, $error, $success, $black, $black-10, $text-black,
 * $white, $ghost-white, $primary-color, $menu-highlight, $faded-white,
 * light-border, $input-border
 */
@import '../theme/eurobridge-color-palette.scss';

// fonts
$font: 'Ubuntu', sans-serif;

// screen widths
$x-large: 85em;
$large: 68.75em;
$medium: 55.625em;
$smallmed: 46.875em;
$small: 36.25em;
