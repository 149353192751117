@import '../../root/globals.scss';

$text-height: 400px;
$modal-width: 800px;

.ReactModal__Content--after-open.email-modal {
  transform: translate(-50.5%, -50.5%);
}

.email-modal {
  div.modal-content {
    max-width: $modal-width;

    .email {
      max-height: $text-height;

      pre { white-space: pre-line; }
    }

    div.editor-toolbar {
      width: 100%;
      margin: 0;

      .editor-button {
        padding: 10px;
        margin: 5px;
      }
    }

    div.options {
      padding: 0;
      border-top: none;
    }
  }
}
