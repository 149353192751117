@import '../root/globals.scss';

.groupage-autoline-modal {
  min-width: 22em;

  .autoline-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .field {
      width: 100%;

      input {
        width: 100%;
      }
    }
  }

  .controls {
    width: 100%;
    padding: 1em;
    display: flex;
    justify-content: space-between;
  }

  .select__single-value {
    width: 100%;
  }

}
