@import '../root/globals.scss';

.form-table {

  display: inline-grid;
  margin-left: 1em;
  margin-top: 1em;

  table {
    display: inline-table;
    margin-right: 3em;
    margin-bottom: 13em;

    input:disabled {
      background: #f2f2f3;
      border-color: #e6e6e6;
    }
  }

  td, th {
    width: 10em;
    min-width: 10em;
    padding: 0;
    margin: 0;

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input, button, .dropdown-button {
      width: 100%;
      box-sizing: border-box;
    }

    button {
      font-weight: 600;
      margin: 0;
      padding: 0.38em;
      border-radius: 4px;
      border: 0;
      box-shadow: none;

      &:hover { opacity: 0.7; }
    }

    input.cell {
      text-align: right;
      padding: 0.5em 0.75em;
      border: 1px solid #D6D6D6;
      border-radius: 4px;
    }

    input.head-col-value {
      padding: 0.5em 0.75em;
      font-weight: 600;
      border: 1px solid #D6D6D6;
      border-radius: 4px;
    }

    input.head-row {
      padding: 0.5em 0.75em;
      border: 1px solid #212121;
      border-radius: 4px;

      &[type="number"] {
        text-align: right;
        padding-right: 0.3em;
      }
    }

    &.col-label {
      position: relative;

      input {
        color: gray;
        padding: 0.5em 0.75em;
        border: 1px solid #D6D6D6;
        border-radius: 4px;

        &[name="0:0"] { border-color: #212121; }
      }

      i, svg {
        position: absolute;
        right: .5em;
        font-size: 1.2em;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .controls {
    padding-top: 2em;
  }

  button {
    font-family: $font;
    &.btn-primary {
      background-color: $primary-color;
      font-size: 0.8em; // to match height
      padding: 0.65em; // of other cells
      border-radius: 4px;

      &:hover {
        background: lighten( $primary-color, 5%);
        opacity: 1;
      }
    }

    &.btn-success {
      background-color: $success;

      &:hover {
        background: lighten( $success, 5%);
        opacity: 1;
      }
    }

    &.btn-default {
      background-color: blue;

      &:hover {
        background: lighten(blue, 5%);
        opacity: 1;
      }
    }

    &.btn-warning {
      background-color: gold;

      &:hover {
        background: lighten(gold, 10%);
        opacity: 1;
      }
    }
  }
}

.supplier-tariff-panel {
  overflow-y: auto;
}
