@import '../../root/globals.scss';

@mixin show-container {
  font-family: $font;

  h1 {
    color: $black;
    margin: 0 0 1.2em 0;
  }

  div.status-container {
    > div {
      margin: 0;
      margin-bottom: 1em;
    }
  }

  .body {
    display: flex;

    @media (max-width: $large) {
      flex-direction: column;
    }

    .main {
      @media (min-width: $large) {
        width: 80%;
      }
      @media (max-width: $large) { /* Sidebar becomes topbar */
        width: 100%;
      }

      background-color: $white;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
      height: 100%;
      border-radius: 4px;
    }
  }
}
