@import '../root/globals.scss';

div.table.Companies {
  div.contents {
    div.row {
      span {
        &.Active::before {
          content: '';
          width: 10px;
          height: 10px;
          background: green;
          border-radius: 50%;
          margin-right: .75em;
          display: inline-block;
        }
        &.Downtrader::before {
          content: '';
          width: 10px;
          height: 10px;
          background: $success;
          border-radius: 50%;
          margin-right: .75em;
          display: inline-block;
        }
        &.Inactive::before {
          content: '';
          width: 10px;
          height: 10px;
          background: $danger;
          border-radius: 50%;
          margin-right: .75em;
          display: inline-block;
        }
      }
    }
  }
}