@import '../root/globals.scss';

.react-tabs {
  -webkit-tap-highlight-color: transparent;

  &__tab-list {
    background-color: lighten($black, 80%);
    margin: 0 0 1em;
    padding: 0;
  }

  &__tab {
    display: inline-block;
    border-bottom: 4px solid transparent;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 1.75em;
    cursor: pointer;
    font-weight: 700;
    transition: all 0.3s ease-in-out;

    &:hover {
      background: lighten($black, 82%);
      border-bottom: 4px solid $primary-color;
    }

    &--selected, &--selected:hover {
      color: $primary-color;
      border-bottom: 4px solid $primary-color;
    }

    &--disabled {
      color: $light-grey;
      cursor: default;
    }

    &:focus {
      outline: none;
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}
