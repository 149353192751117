@import '../../root/globals.scss';

@mixin sidebar {
  background-color: $white;
  margin-right: 2em;
  padding: 1.5em 2em;
  min-width: 16em;
  width: 20%;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);

  @media (max-width: $large) { /* Sidebar becomes top bar */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-left: 0;
    margin-right: 0;
    padding-right: 0;
    margin-bottom: 2em;

    div.details {
      border-right: 1px dotted $light-grey;
      padding-left: 1em;
      padding-right: 1em;
      flex-basis: 100%;

      &:last-child {
        border-right: none;
      }
    }
  }

  @media (max-width: $large) { /* Hide least important box */
    .quaternary {
      display: none;
    }
  }

  @media (max-width: $medium) { /* Hide least important box */
    .tertiary {
      display: none;
    }
  }

  @media (max-width: $small) { /* Just show most important box */
    .secondary {
      display: none;
    }
  }

  .details {
    h1 {
      word-break: break-word;
    }
  }

  .details {
    .info {
      display: flex;
      justify-content: space-between;
      line-height: 150%;
    }
  }


  @media (min-width: $large) {
    .details {
      border-bottom: 1px dotted $light-grey;
      padding-bottom: .8em;
    }
  }


  .info {
    padding-bottom: 1em;

    label {
      color: $grey;
      padding-right: 1em;
    }

    span {
      word-break: break-all;
    }
  }

  .status {
    i {
      height: 0.6em;
      width: 0.6em;
      border-radius: 50%;
      display: inline-block;
      margin-right: 0.5em;
      margin-bottom: 0.03em;
    }

    i.Active {
      background-color: green;
    }
  }
}
