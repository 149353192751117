@import '../root/globals.scss';

$row-separation: 0.4em;
$column-separation: 0.4em;

.consignment-details {
  flex-wrap: wrap;
  flex-direction: row;

  .field {
    width: 11em;
    max-width: 11em;
    min-width: 11em;
  }
}

.package-dimension-lines-calculator {
  margin: 0 1em 0 1em;
  width: 100%;

  /* Hide HTML5 Up and Down arrows. */
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  div.lines-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 1em;

    // Degrade gracefully by showing a horizontal scrollbar, but this prevents
    // the select menus from overflowing vertically (browser issue), so we
    // need to add padding
    @media (max-width: $medium) {
      overflow-x: auto;
      padding-bottom: 10em;

      .select__menu-list {
        max-height: 8.5em;
      }
    }
  }

  div.dimension-line {
    &:not(:first-child) {
      div.field {
        label {
          display: none;
        }
      }
    }

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;
    width: 100%;

    div.field {
      margin: 0;
      padding: 0;
      flex: 1.6;
      min-width: 6.5em;

      &.flex-2 {
        min-width: 10em;
      }


      margin-top: $row-separation;

      &:not(:last-child) {
        margin-right: $column-separation;
      }
    }

    div.field.flex-1 {
      flex: 1
    }

    button.remove-dimension-line {
      margin: 0;
      padding: 0 1em 0 1em;
      height: 3.3rem;
      vertical-align: bottom;
      background-color: $error;

      svg {
        padding: 0;
      }
    }
  }

  button.calculate {
    margin: 0;
    margin-top: $row-separation;
    margin-left: $column-separation;
    background-color: $success;
  }

  button.new-dimension-line {
    margin-left: 0;
    margin-right: 0;
    margin-top: $row-separation;
    background-color: $primary-color;
  }

}
