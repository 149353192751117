@import '../../root/globals.scss';


.date-selector-field {
  
  div.react-datepicker-wrapper {
    width: 100%;

    div.react-datepicker__input-container {
      width: 100%;
    }
  }

}
