@import '../../root/globals.scss';

$transition-time: 0.3s;

@mixin select-mixin {
  font-size: 0.9rem;
  font-weight: 300;
  $height: 3.3rem;

  > div:first-of-type {
    height: $height;
    line-height: $height;

    > div {
      height: inherit;
      padding-left: 1em;
    }
  }

  svg {
    height: 100%;
  }

  input {
    position: absolute;
    top: 0px;
    left: 1em;
    text-indent: 0;
  }
}

div.select {
  @include select-mixin;
}

@mixin select__option-mixin {
  &:hover {
    background-color: lighten($success, 45%);
    color: $black;
  }

  &--is-focused {
    background-color: lighten($success, 45%);
  }

  &--is-selected {
    background-color: $success;
  }
}

div.select__option {
  @include select__option-mixin;
}

@mixin select__control-mixin {
  &--is-focused {
    border-color: $success;
    transition: border-color $transition-time ease-in-out;
    box-shadow: none;

    &:hover {
      border-color: $success;
    }
  }
}

div.select__control {
  @include select__control-mixin;
}

div.field {
  &.field-error {
    div.select__control {
      border-color: $error;

      &:hover {
        border-color: $error;
      }

      &--is-focused {
        border-color: $success;
        transition: border-color $transition-time ease-in-out;
        box-shadow: none;

        &:hover {
          border-color: $success;
        }
      }
    }
  }
}

div.multi {
  > div:first-of-type {
    height: unset;
    line-height: 2.2rem;
    min-height: 3.3rem;
  }

  input {
    height: 2.2rem;
    text-indent: unset;
    position: unset;
  }
}
