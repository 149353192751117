@import '../../root/globals.scss';

$text-height: 400px;
$modal-width: 800px;

.email-modal {
  min-width: $modal-width;

  div.modal-content {
	display: block;

	h4 { margin: 0; }

	div.form-status.error { margin: 0; }

	.email {
	  max-height: $text-height;
	  pre { white-space: pre-line; }
	}

	div.after-email-modal {
	  width: 100%;
	  text-align: center;
	  margin: 17px 0;
    }

    div.options {

      button.danger {
        margin: 0;
        &:hover { background-color: darken($danger, 10%); }
      }

      button.primary {
        &:hover { background-color: darken($primary-color, 10%); }
      }
    }

    div.after-options {
      display:flex;
      justify-content: flex-end;
      margin-top: 24px;

      span {
        display:flex;
        align-self: center;
        justify-content: flex-end;
      }

      button {
        transition: none;
        width: 140px;
        padding: 12px 24px;
        margin: 0 0 0 30px;

        &.danger:hover {
          background-color: darken($danger, 10%);
        }

        &.primary:hover {
          background-color: darken($primary-color, 10%);
        }

      }
    }
  }
}
