@import '../root/globals.scss';
@import '../generic/Pane.scss';

.quotes-history {

  background-color: $white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 0.5em;
  @include pane;

  h3, h4 {
    margin-top: 0;
  }

  .quote {
    width: 22em;
    border-radius: 0 4px 4px 0;
    padding: 1em;
    margin: 0.5em;
    background-color: #F9F9F9;

    &.Active {
      border-left: 4px solid orange;
    }
    &.Expired {
      border-left: 4px solid red;
    }
    &.Converted {
      border-left: 4px solid green;
    }

    div {
      display: flex;
      flex-basis: column;
      justify-content: space-between;
      padding-bottom: 0.3em;
    }

    .ref {
      font-weight: 900;
      font-size: 110%;
      padding-bottom: 1em;
      border-bottom: 1px solid #f1f1f1;
      margin-bottom: 1em;

      span:last-of-type {
        font-weight: 500;
        opacity: 0.5
      }

      a {
        color: inherit;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .rate {
      font-weight: 900;
    }

  }
}
