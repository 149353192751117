@import '../../root/globals.scss';

$control-height: 48px;


div.planner-controls {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  justify-content: flex-start;
  flex-wrap: wrap;

  > div {
    margin-bottom: 1em;
  }

  &.column-mode {
    flex-direction: column;
    align-items: flex-start;
  }

  div.week-and-column {
    display: flex;

    div {
      margin: 0 1em 0 0;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  border-bottom: 1px solid $input-border;
}

div.planner-control {
  display: flex;
  align-items: center;
  margin: 0 1.5em 0 0;
  line-height: 100%;

  .select__placeholder {
    color: $text-black;
  }
}

.week-control {
  display: flex;
  line-height: $control-height;

  .react-datepicker__day-name {
    &:first-of-type {
      padding-right: 1em;
    }
  }

  .react-datepicker__week-number {
    color: $black;
    font-weight: 600;
    border-right: 1px solid $input-border;
    padding-right: 1em;
  }

  .react-datepicker__day--selected, .react-datepicker__day--highlighted {
    background-color: $primary-color;
    border-radius: 0;
  }

  .mid-week-range {
    background-color: #ECF6EC;
    margin: 0;
    padding: 0 0.166rem;
  }

  .start-and-end {
    background-color: $primary-color;
    color: $white;
  }
}

.week-picker {
  display: relative;
  cursor: pointer;

  border-color: $success;
  transition: border-color 0.3s ease-in-out;

  input {
    &:focus-within {
      border-color: $success;
      transition: border-color 0.3s ease-in-out;
    }
  }

  span:focus-within ~ input {
    border-color: $success;
    transition: border-color 0.3s ease-in-out;
  }
}

.planner-week-picker {
  width: 18em;
  padding-left: 3.5em;
  line-height: $control-height;
  outline: none;
  box-sizing: border-box;
  border: 1px solid $input-border;
  border-radius: 0 5px 5px 0;
  font-weight: 600;
  border-left: 1px solid transparent;
  cursor: pointer;
}

input {
  // font declaration required to make firefox render consistently with chrome.
  font: 400 13.3333px Arial;
}

.week-picker-icon {
  position: absolute;
  top: 2px;
  left: calc(1em - 1px);
  line-height: $control-height;
  cursor: pointer;
}

.date-week-switcher {
  display: flex;
  color: $white;
  font-weight: 500;

  div {
    display: flex;
    justify-content: center;
    width: $control-height;
    font-size: 0.8em;
    cursor: pointer;
    border: 1px solid transparent;

    &:first-of-type {
      border-radius: 5px 0 0 5px;
    }
  }

  .on {
    background-color: $primary-color;
  }

  .off {
    background-color: $input-border
  }
}

.week-picker-label {
  position: absolute;
  line-height: $control-height;
  top: 2px;
  left: calc(13.7em - 1px);
  // padding-left: 9em;
  font-size: 0.8rem;
  font-weight: 300;
  color: hsl(0,0%,60%);
  cursor: pointer;
}

button.columns {
  padding: 0;
  font-size: 1.4em;
  padding: 0;
  margin: 0;
  border-radius: 0;
  color: $black;
  box-shadow: none;
  background: none;
  svg {
    path { fill: $black; }
    &:hover {path { fill: $grey; }}
  }
  &:hover { box-shadow: none; }
}

.country-picker, .status-picker {
  min-width: 240px;
}
