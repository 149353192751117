@import '../../../root/globals.scss';
@import '../../../generic/show/general.scss';

.emails {
  @include general;
  margin: 0 1em 0 1em;

  .jobs {
    display: flex;
    flex-direction: column;
    margin: 0 1em 0 1em;
  }

  .jobs .job-row {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 4fr 4fr;
    grid-template-rows: 1fr;
    min-height: 2.1em;
    padding: 0.6em 0;
    margin: 0.25em 0;
    background-color: $faded-white;
    border-radius: 5px;
    align-items: center;

    &.header {
      background-color: $white;
      font-weight: 600;

      span.invoice-emailed {
        text-align: center;
      }

    }

    div.checkmark {
      display: flex;
      font-size: 20px;
      justify-content: center;
      align-items: center;
    }

    span {

      a {
        text-decoration: none;
        color: $primary-color;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    span.customer-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span.icon {
      text-align: center;
    }

      span.detail {
        display: flex;
        flex-direction: column;
        color: $error;

        &.posted {
          color: $grey;
          font-style: italic;
        }
      }
    }

  div.button-row {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5em;
    margin-bottom: 2em;
  }

  div.status-messages {
    display: flex;
    flex-direction: column;

    span {
      margin-bottom: 0.5em;
    }
  }

}


