@import '../../root/globals.scss';

.warehouse-modal {

  div.modal-content {
    max-height: 500px;
    max-width: 800px;
    overflow-y: scroll;

    * {
      margin-left: 0;
      margin-right: 0;
    }

    .warehouse-attributes {
      padding-right: 1em;

      div.double-input div:last-of-type { margin-right: 0; }
    }

    div.warehouse-requirements {
      * {
        margin-left: 0;
        margin-right: 0;
      }
    }

    span.name {
      padding-bottom: 1em;
    }
  }

}
