@import '../theme/eurobridge-color-palette.scss';

.quote-details-summary {
  background: lighten($primary-color, 50%);
  border: 2px solid $primary-color;
  margin: 0 0 2em 0;
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
  padding: 0.75em;

  .detail {
    padding: 1em;
    width: 9em;
    display: grid;

    .label {
      font-weight: 600;
      padding-right: 0.5em;
      padding-bottom: 0.3em;
    }
  }
}
