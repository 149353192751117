@import '../../root/globals.scss';

div.quick-find-container {
  position: relative;
  @media only screen and (max-width: $medium) {
    display: none;
  }
}

div.quick-find {
  box-sizing: border-box;
  border-right: 1px solid $light-border;
  position: relative;
  z-index: 10;

  div.query-type-selector {
    width: 170px;
    z-index: 10;
    display: flex;
    align-items: center;

    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    &:hover {
      color: $black;

      div.query-type-option {
        color: $black;
      }
    }

    span.dropdown-icon {
      background-color: $white;
      z-index: 10;
      padding-right: calc(1em - 1px);
      transition: all 0.3s ease-in-out;
    }

  }

  .quick-find-query-dropdown {
    position: absolute;
    top: -100%;
    right: -1px;
    background-color: $white;

    height: auto !important;
    box-shadow: 0 5px 6px rgba(0,0,0,0.08);
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    z-index: -1;

    &.expanded {
      top: $topbar-height;
      opacity: 1;
      pointer-events: all;
      z-index: 5;
    }

  }

  div.query-type-option {
    display: flex;
    align-items: center;
    background-color: $white;
    color: $grey;
    height: 60px;
    border: 1px solid $light-border;
    border-top: 0;
    transition: all 0.3s ease-in-out;
    padding-right: 1em;

    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    &:not(.selected-query) {
      padding-right: calc(2.5em + 1px);
      padding-left: 0.5em;

      &:hover {
        color: $black;
      }
    }

    &.selected-query {
      height: 100%;
      border-left: 0;
      border-right: 0;
      width: 100%;
      justify-content: center;
      padding: 0 1em 0 0;
    }

    &:first-of-type {
      border-top: 1px solid $light-border;
    }

    .model-icon {
      padding-right: 0.7em;
      margin-left: calc(1.5em + 2px);
    }
  }

  div.query-input {
    display: flex;
    align-items: center;
    padding: 0 1em 0 2em;

    span.search-icon {
      color: $black;
      display: flex;
      align-items: center;
    }

    input.query {
      width: 160px;

      font-family: $font;
      font-size: 14px;
      margin-left: 1em;
      border: 0;
      padding: 20px 0;
      appearance: none;
      outline: none;
    }
  }

}
