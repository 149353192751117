@import '../root/globals.scss';

div.trailer-planner {
  font-size: 1rem;

  .header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
