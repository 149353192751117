@import '../../root/globals.scss';
@import '../../generic/Pane.scss';

.admin-settings-container {
  font-family: $font;

  div.main-header {
    display: inline-block;
    width: 100%;

    h1 {
      color: $primary-color;
      float: left;
    }

  }

  div.admin-settings-content {
    @include pane;
  }

}
