@import '../../root/globals.scss';

div.accordion {
  flex: 1;
  margin: 2em;

  div.header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-weight: 500;
    margin: 0 0em 1.75em 0;

    span {
      color: $primary-color;
    }

    .new-item {
      display: inline-block;
      cursor: pointer;
    }

    span.icon {
      margin-right: 0.5em;
    }

    h4 {
      margin: 0;
      min-width: 120px;
    }
  }
}

div.item {
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  border: 1px solid $input-border;
  border-radius: 2px;
  margin-bottom: 0.25em;

  div.item-name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: $faded-white;
    margin: 0;
    padding: 1em;
    cursor: pointer;

    box-sizing: border-box;
    border-bottom: 1px solid $input-border;

    span.icon {
      color: $light-grey;
    }
  }

  div.item-details {
    flex: 1;
    margin: 0;
    padding: 1em;
  }
}
