@import '../root/globals.scss';

$hover-border-width: 0.3em;

@mixin navbar-container {
  font-family: $font;
  color: $grey;

  flex-grow: 0;
  flex-shrink: 0;

  box-sizing: border-box;
  border-right: 1px solid $light-border;

  position: relative;
  z-index: 5;

  transition: all 0.3s;

  .navbar-label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 1em;
    white-space: nowrap;
    font-size: 15px;
    width: 68%;

    .navbar-chevron {
      float: right;
      transition: all 0.3s ease-in-out;
    }

    .chevron-selected {
      transform: rotate(90deg);
    }
  }

  .navbar-subgroup-label {
    position: absolute;
    left: 70px;
    white-space: nowrap;
    font-size: 15px;

  }
}

.navbar-expanded {
  @include navbar-container;
  max-width: $navbar-expanded-width;
  flex-basis: $navbar-expanded-width;

  .navbar-label {
    opacity: 1;
    transition: all 0.3s ease-in;
  }
}

.navbar-collapsed {
  @include navbar-container;
  max-width: $navbar-collapsed-width;
  flex-basis: $navbar-collapsed-width;

  .navbar-label {
    opacity: 0;
    transition: all 0.3s ease-in;
  }
}

.navbar-group-container {
  position: relative;
  cursor: pointer;
}

.navbar-icon-container {
  display: inline-block;
  margin-left: calc(#{$navbar-collapsed-width} / 3);
  padding-right: 0.8em;
  transform: translate(-$hover-border-width);
  transition: all 0.3s ease-in-out;
}

@mixin navbar-hover {
  &:hover {
    background-color: $menu-highlight;
    color: $black;
    border-left: $hover-border-width solid $primary-color;

    span {
      color: $black;
    }
  }
}

.navbar-group {
  @include navbar-hover;
  display: block;
  position: relative;
  background-color: $white;
  min-height: $navbar-group-height;
  line-height: $navbar-group-height;

  box-sizing: border-box;
  border-left: $hover-border-width solid transparent;

  transition: all 0.3s ease-in-out;

}

.navbar-group-selected {
  color: $black;

  + .navbar-drop-down .navbar-item-selected, + .navbar-drop-side .navbar-item-selected {
    background: $black-10;
    border-left-color: $black;
    color: $black;
  }
}

.navbar-group-side {
  display: block;
  position: relative;
  min-height: $navbar-group-height;
  line-height: $navbar-group-height;
  padding-left: 1em;
  background-color: $white;
  color: $black;
}

@mixin navbar-drop {
  box-sizing: border-box;
  border: 1px solid;
  border-left: 0px;
  border-color: $light-border;
  background-color: white;
}

.navbar-drop-down {
  @include navbar-drop;
  border-right: 0px;
}

.navbar-drop-side {
  @include navbar-drop;
  position: absolute;
  z-index: 9001;
  top: 0px;
  left: $navbar-collapsed-width;
  width: $navbar-expanded-width;

  .navbar-label {
    opacity: 1;
    transition: all 0.3s ease-in;
  }

  box-shadow: 5px 0 10px rgba(0,0,0,0.08);
}

.navbar-subgroup {
  @include navbar-hover;
  display: block;
  // padding-left: 1em;
  background-color: $white;
  min-height: $navbar-subgroup-height;
  line-height: $navbar-subgroup-height;

  box-sizing: border-box;
  border-left: $hover-border-width solid transparent;
}

@mixin navbar-item {
  @include navbar-hover;
  display: block;
  min-height: $navbar-subgroup-height;
  line-height: $navbar-subgroup-height;

  box-sizing: border-box;
  border-left: $hover-border-width solid transparent;

  color: $grey;

  transition: all 0.3s ease-in-out;
}

.navbar-item {
  @include navbar-item;
  position: relative;

  &:hover {
    background-color: $menu-highlight;
    color: $black;
    border-left: $hover-border-width solid $primary-color;

    span {
      color: $black;
    }
  }

  &.navbar-item-selected {
    color: $primary-color;
    border-left: $hover-border-width solid $primary-color;
  }
}

.navbar-item-in-group {
  @include navbar-item;
  background-color: $ghost-white;
  transition: all 0.3s ease-in-out;
  position: relative;

  &:hover {
    background: $black-10;
    border-left-color: $black;
  }
}

.navbar-item-in-subgroup {
  @include navbar-item;
  background-color: $faded-white;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.navbar-item-link {
  text-decoration: none;
}
