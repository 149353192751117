@import '../root/globals.scss';

div.email-form {
  div.item-name { font-weight: 1000; }
  div.editor {
    margin: 0 1em;
    width: auto;
  }
}

