@import '../../root/globals.scss';

div.all-opening-times-container {
  max-height: 600px;
  width: 100%;
  overflow-y: auto;

  display: flex;
  flex-direction: column;

  div.address-string {
    margin-bottom: 1em;
  }

  button.new-opening-times {
    background: $primary-color;
    white-space: nowrap;
  }

  div.opening-time-container {
    margin-bottom: 0.25em;

    div.opening-time-header {
      flex: 1;
      display: flex;
      flex-direction: row;
      padding: 0.5em 1em 0.5em 1em;

      min-height: 3em;

      background-color: $faded-white;
      box-sizing: border-box;
      border: 1px solid $input-border;
      border-radius: 2px;
    }

    div.opening-time {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;

      div.days {
        flex: 1;
        min-width: 11em;
        white-space: nowrap;
      }

      div.time {
        flex: 2;
        justify-content: flex-start;
        min-width: 6em;
        white-space: nowrap;
        padding-left: 2em;
      }
    }

    div.options {
      display: flex;
      justify-content: flex-end;
      margin: 0;
      padding: 0;
      border: 0;

      button.edit {
        background: $primary-color;
        margin: 0;
        margin-left: 5em;
        margin-right: 1em;
      }

      button.delete {
        background: $error;
        margin: 0;
      }
    }

    div.opening-time-form-container {
      box-sizing: border-box;
      border: 1px solid $input-border;
      border-top: 0;
      padding: 1em;
      border-radius: 2px;

      div.form {
        padding-bottom: 1em;

        div.time-form {
          display: flex;


          div.field:first-child {
            margin-left: 0;
          }

          div.field {
            margin-top: 0;
            width: 100%;
          }
        }

        input {
          height: 2.6em;
          padding: 0.5em;
          text-indent: 0;
        }


        div.day-form {
          display: flex;
          justify-content: space-between;

          span {
            padding-bottom: 0.25em;
          }

          div.day-toggle {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 1em 0 0;
          }
        }
      }

      div.form-options {
        padding-top: 1em;
        display: flex;
        flex-direction: row;
        border-top: 1px solid $input-border;
        color: $primary-color;
        justify-content: flex-end;

        div {
          cursor: pointer;
        }

        div.discard {
          color: $error;
        }

        :not(:last-child) {
          margin-right: 2em;
        }
      }
    }
  }
}
