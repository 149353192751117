@import '../root/globals.scss';

.toggle-field {
  display: flex;
  margin: 0 1em 1em 1em;
  min-width: 20em;
  width: 46%;

  .checkbox {
    flex: 1;
    margin-top: 2em;
    height: 2.15rem;
    border-radius: 5px;
    padding: 0.57em 0;
  }

  .rate {
    flex: 1;
    margin-left: 1em;
    height: 100%;
    label {
      font-size: 0.9rem;
      color: $text-black;
      padding-bottom: 0.8em;
    }
    div {
      margin-top: 0.8em;
    }
  }

  .toggle {
    min-width: 5em;
    padding-top: 1.3em;
    white-space: nowrap;
    padding-right: 1em;
  }

  .sff-input-group {
    border-radius: 5px;
    border: 1px solid #D6D6D6;
    box-sizing: border-box;
    transition: border-color 0.3s ease-in-out;
    span {
      border: 0;
    }
    input {
      font-family: inherit;
      font-weight: 300;
      font-size: 0.9rem;
      text-indent: 1em;
      padding-right: 1em;
      width: 100%;
      height: 3.1rem;
      border: 0;
    }
  }


}
