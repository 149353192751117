@import '../../root/globals.scss';
@import '../../generic/Pane.scss';

.user-management-container {
  font-family: $font;

  div.main-header {
    display: inline-block;
    width: 100%;

    h1 {
      color: $primary-color;
      float: left;
    }

    button.add-new-user {
      margin-top: 1.5em;
      margin-right: 0;
      background-color: $primary-color;
      color: $white;
      background-color: $primary-color;
      float: right;

      &:hover {
        background: darken($primary-color, 10%);
        box-shadow: 0 0 10px rgba(0,0,0,0.2);
      }
    }
  }
}

.user-management-pane-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

@mixin user-management-pane {
  @include pane;
  flex: 1;
}

.user-management-add-new-users {
  @include user-management-pane;
  margin-left: 1em;

  div.form-container {
    display: block;
    padding-top: 0.6em;
  }

  @mixin input {
    height: 3.5em;
    width: calc(100% - 0.5em);
  }

  div.field {
    input {
      @include input;
    }
  }


  div.toggle-box {
    margin: 1em 0 1em 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  div.user-role-toggle-item {
    width: 30%;
    height: 3em;
    line-height: 3em;
    font-weight: bold;
    display: flex;
    align-items: center;
    font-size: 0.9rem;

    span {
      padding: 0.75em;
      color: $text-black;
      font-size: 1.3em;
    }

    @media only screen and (max-width: 1600px) {
      width: 45%;
    }

    @media only screen and (max-width: 1200px) {
      width: 55%;
    }
  }

  div.button-container {
    display: flex;
    flex-direction: row;

    span {
      margin: 1em;
      font-weight: 600;
      align-self: center;
    }

    button {
      min-width: 7em;
    }
  }

  button.save-details {
    background-color: $primary-color;

      &:hover {
        background: darken($primary-color, 10%);
        box-shadow: 0 0 10px rgba(0,0,0,0.2);
      }
  }

  button.discard-changes {
    background-color: $error;

    &:hover {
      background: darken($error, 15%);
      box-shadow: 0 0 10px rgba(0,0,0,0.2);
    }
  }
}

.user-management-manage-users {
  @include user-management-pane;
  margin-right: 1em;
  max-height: 45em;
  overflow-y: auto;
  overflow-x: hidden;

  div.user-row {
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: 1200px) {
      flex-direction: column;
    }
    justify-content: space-between;
    margin-left: 1em;
    margin-right: 1em;
    border-bottom: 1px solid $ghost-white;
    min-height: 4.6em;

    &:last-of-type { border-bottom: none; }
  }

  @mixin user-row-block {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 1em;
    padding-top: 1em;
  }

  div.user-row-left {
    @include user-row-block;
    max-width: 75%;
  }

  div.user-row-name {
    font-weight: 400;
  }

  span.user-row-new-user-label {
    color: $success;
  }

  span.user-row-email {
    font-weight: 300;
    font-size: 0.8em;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div.user-row-right {
    @include user-row-block;
    text-align: right;
    font-size: 0.8em;
    white-space: nowrap;
  }

  div.user-row-permissions {
    font-size: 1.2em;
    color: $grey;
    margin-bottom: 1em;

    * {
      padding-left: 0.75em;
    }
  }

  div.user-row-controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    span {
      cursor: pointer;
      transition: all 0.3s ease-in-out;
    }
  }

  span.user-row-edit {
    color: $primary-color;

    &:hover { color: darken($primary-color, 20%); }
  }

  span.user-row-delete {
    color: $error;
    margin-left: 2.5em;
    &:hover { color: darken($error, 20%); }
  }
}

.user-row-confirm-delete-modal {
  span.name {
    padding-bottom: 0.5em;
  }

  span.email {
    font-size: 10pt;
  }
}
