@import '../../root/globals.scss';

.orderable-select {
  color: $text-black;
  width: 100%;
  margin: -1em 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .drag-n-drop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;      

    .dnd-icon {
      width: 3em;
    }
    .dnd-text {
      margin-top: 1em;
      font-size: 0.8em;
      color: $primary-color;
    }
  }

  .drop-zone-header {
    font-weight: 500;
    font-size: 1.2em;
    user-select: none;
    margin-top: 1em;
  
    &:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }

  .drop-zone {
    border: 1px solid hsl(0, 0, 80);
    padding: 0.2em;
    width: 18em;
    max-height: 50vh;
    overflow-y: scroll;

    display: flex;
    flex-wrap: wrap;
  }

  .available {
    .item {
      opacity: 0.7;
    }
  }

  .item {
    box-sizing: border-box;
    border-radius: 6px;
    background: $menu-highlight;
    padding: 0.5em 1em;
    margin: 0.2em;
    cursor: grab;
    width: 100%;
 
    &.dragging {
      opacity: 0.25 !important;
    }
  }

  .ghost {
    min-width: 5em;
    min-height: 2em;
    flex-grow: 1
  }
}
