@import '../../generic/show/controls.scss';
@import '../../root/globals.scss';


.job-controls {

  @include controls;
}

.modal-window {
  div.modal-content {
    div.options {
      justify-content: space-between;
    }
  }
}

.dropdown-button {
  button.secondary { padding: 0.75em 1em; }

  svg {
    padding: 0 5px 0 0;
    margin-right: 5px;
  }

  .email-options {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: inherit;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 5px;

    button {
      margin: 0;
      background: none;
      color: $black;
      box-shadow: none;
      border-radius: 0;
      text-align: left;

      label {
        margin: 15px;
        cursor: pointer
      }


      &:first-of-type {
        border-radius: 5px 5px 0 0;
      }

      &:last-of-type {
        border-radius: 0 0 5px 5px;
      }


      &:hover {
        background-color: $menu-highlight;
      }

      &:disabled {
        cursor: default;

        label { cursor: default; }

        &:hover {
          background-color: white;
        }
      }
    }


  }
}
