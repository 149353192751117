@import '../root/globals.scss';

div.contact-form {

  div.field {
    margin-top: 0;
  }

  .contact-information-lines .dropdown-content { left: 1em; }

}
