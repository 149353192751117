@import '../root/globals.scss';
@import '../generic/show/general.scss';

.quote-new {
  @include general;

  .form {
    max-width: $medium;
  }

  .step-controls {
    display: flex;
    justify-content: space-between;
  }

  .section.expand-section {
    .lines-container {
      .dimension-line {
        .field {
          min-width: 5em;
        }
      }
    }
  }

  .quote-details {
    .checkbox {
      min-width: 10em;
      height: 2.45em;
      margin: 1.9em 0.5em 0 0.5em;
      padding: 0.5em;
    }
  }

  .toggle-list {
    margin: 0 1em 1em 1em;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}
