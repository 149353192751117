@import '../../root/globals.scss';

$field-height: 3.5em;
$form-width: 70%;

.password-reset-container {
  font-family: $font;

  display: flex;
  flex-direction: row;
  height: 100vh;

  * {
    font-family: $font;
    font-size: 1em;
  }
  color: $black;
}

.password-reset-left {
  flex: 1;
  position: relative;
  background-color: $white;

  display: flex;
  flex-direction: column;
  align-items: center;

  overflow-y: scroll;
}

.password-reset-logo-container {
  flex: 1;
  width: $form-width;
  margin-left: 2em;
  margin-top: 5em;
}

.password-reset-logo {
  width: 200px;
}

div.password-reset-title {
  margin: 1em;
  margin-bottom: 2.5em;
}

.password-reset-form-container {
  flex: 1;
  width: $form-width;
  max-height: 50%;

  transform: translate(0, -50%);

  h1 {
    font-size: 2.9em;
    line-height: 100%;
    letter-spacing: 2px;
    margin-top: 0;
    margin-bottom: 0.75em;
  }

  span {
    color: $grey;
    letter-spacing: 0.4px;
  }
}

@mixin input {
  $input-text-left-padding: 4em;
  height: $field-height;
  width: calc(100% - #{$input-text-left-padding});
  padding-left: $input-text-left-padding;
  margin-bottom: 0.75em;
}

.password-reset-field {
  flex: 1;
  input {
    @include input;
  }
}

.password-input-icon-container {
  position: absolute;
  width: 60px;
  height: $field-height;
  margin: 2px;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.password-input-icon-container:after {
  content: '';
  position: absolute;
  right: 0;
  top: 15%;
  height: 70%;
  border-style: none solid none none;
  border-width: 1px;
  border-color: $input-border;
  transition: all 0.3s ease-in-out;
}

.password-input-icon-svg {
  color: $light-grey;
}

button.send-password-reset {
  background-color: $primary-color;
  color: $white;
  margin-top: 0.75em;
  margin-right: 2em;
  margin-bottom: 3em;
  padding: 0.75em 2.5em 0.75em 2.5em;
  font-size: 18px;

  &:hover {
    background: darken($primary-color, 10%);
  }
}

.password-reset-right {
  flex: 1.25;
  position: relative;
  background: $primary-color;
  background: -moz-linear-gradient(45deg, $primary-color 0%, darken($primary-color, 25%) 100%);
  background: -webkit-linear-gradient(45deg, $primary-color 0%, darken($primary-color, 25%) 100%);
  background: linear-gradient(45deg, $primary-color 0%, darken($primary-color, 25%) 100%);

  .password-reset-image {
    width: 75%;
    height: 75vh;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.password-reset-image-container {
  overflow: hidden;
}

.password-reset-image {
  width: 75%;
  height: 75vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.password-reset-service-terms {
  position: absolute;
  bottom: 3%;
  right: 4%;
  color: $white;

  span {
    color: $white;
    font-size: 14px;
    text-decoration: none;
  }

  a {
    text-decoration: none;
  }
}
