@import '../../root/globals.scss';

div.select-modal-form-container {
  min-width: 400px;
  margin: 1em -1em 0em -1em;

  div.form-status {
    margin: 1em 1em 0 1em;
  }

  button.create-option {
    float: right;
    margin-right: 1em;
    background: $primary-color;
  }

}

div.children-container {
  position: relative;

  div.make-room {
    width: calc(100% - 3.6em);
  }

  button.open-new-modal {
    position: absolute;
    bottom: 0;
    right: 0;

    height: 3.3rem;
    background: $primary-color;

    margin: inherit;
    padding: 0 1em 0 1em;

    svg {
      padding: 0;
    }

  }

}

button.open-new-modal {
  background: $primary-color;
}
