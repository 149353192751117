@import '../root/globals.scss';

@mixin pane {
  padding: 1em 1em 1em 1em;
  background-color: $white;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(0,0,0,0.08);

  div.header-rule {
    border-bottom: 1px solid $ghost-white;
    margin: 0 -1em 1em -1em;
  }

  div.header {
    margin: 0.4em 1em 1em 1em;
    width: 100%;

    h3, h4 {
      margin-top: 0;
      font-family: $font;
    }
  }

  div.rule {
    margin-top: 1em;
    margin-bottom: 1em;
    border-bottom: 1px solid $ghost-white;
  }
}
