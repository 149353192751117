@import '../../root/globals.scss';

.contacts-tab {
  .controls {
    padding: 1em;
  }
}

div.company-contacts-container {
  display: flex;
  flex-direction: row;

  .accordion { margin-top: 1em; }

  div.information-line {
    display: flex;
    // min-height: 2.5em;
    margin-bottom: 1em;

    &:last-of-type { margin-bottom: 0; }

    label {
      flex: 0 0 2em;
      color: $black;
    }

    span {
      font-weight: 300;
    }
  }

  div.details-options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 1.75em;
    border-top: 1px solid $input-border;
    margin-top: 1em;

    div {
      display: flex;
      align-items: center;
      color: $primary-color;
      font-size: 13px;
      padding: 1.25em 2em 0 2em;
      min-width: 8em;

      span {
        cursor: pointer;
      }

      a {
        text-decoration: none;
        outline: 0;
        color: $primary-color;
        cursor: pointer;
      }

      label {
        padding-right: 0.5em;
        cursor: pointer;
      }
    }

    div:last-of-type {
      color: $error;

      a { color: $error; }
    }

    div.destroy {
      color: $error;
    }
  }

  div.item-details { padding: 1em 0; }

  div.address-line {
    display: flex;
    min-height: 1.5em;
    font-weight: 300;
  }

  div.address-type {
    padding: 1em 0 0 0;
    font-weight: 600;
  }

  span.warehouse-modal-link {
    color: $primary-color;
    cursor: pointer;
  }

  div.details-contacts{
    padding: 0.5em 1em;
  }

  div.details-address{
    padding: 0.5em 1em 1em 1em;
  }

  div.edit-address-container {
    .react-tabs {
      -webkit-tap-highlight-color: transparent;

      &__tab-list {
        background-color: $white;
        margin: 0 0 2em;
        padding: 0;

        border-bottom: 1px solid $light-grey;
      }

      &__tab {
        padding: 0 1em 1em 1em;
        bottom: -1px;

        &--selected, &--selected:hover {
          color: $primary-color;
          border-bottom: 4px solid $primary-color;
        }

        &:hover {
          background: $white;
          border-bottom: 4px solid $primary-color;
        }

        &--disabled {
          visibility: hidden;
          padding: 0;
          transition: none;
        }
      }
    }
  }

}

.contact-delete-modal {
  span.name {
    padding-bottom: 2em;
  }

  div {
    margin: 1em 0 1em 0;
  }
}
