@import '../../root/globals.scss';

.planner-block-wrapper {

  &.booking, &.status {
    border-radius: 4px;
  }

  &.booking {
    background-color:$white;
    box-shadow: 0 1px 1px 0 hsla(0, 0%, 0%, 0.19);

    .bw-header {
      color: $primary-color;
    }
  }

  &.status {
    background-color: hsla(0,0,50,1);//thistle;

    .bw-header {
      color: $white;
    }

    .footings {
      span { color: $white; }
    }
  }

  .bw-header {
    vertical-align: center;
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: 500;
    border-bottom: 1px solid $input-border;
    font-size: 1.2em;
    height: 2.6em;

    span {
      a {
        color: $primary-color;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    span[title] {
      cursor: help;
    }

    svg {
      padding-left: 0.5em;
      padding-right: 0.5em;
    }

    .icon {
      display: flex;
      justify-content: center;
      width: 55px;
    }

    .reference {
      margin-left: 0.5em;
      white-space: nowrap;
    }

    .date {
      margin: 0 2em;
    }

    .eta {
      margin: 0 1em;

      @media (max-width: $small) {
        display: none;
      }
    }

    .agent-ref {
      @media (max-width: $large) {
        display: none;
      }
    }

    .info {
      margin: 0 1em;
      color: $text-black;
      font-weight: 400;

      @media (max-width: $medium) {
        display: none;
      }
    }

    .spacer {
      flex-grow: 1;
    }

    .booking-status {
      width: 5em;
      background-color: $primary-color;
      color: $white;
      border-radius: 4px;
      float: right;
      text-align: center;
      padding: 0.4em;
    }

    .options {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      color: $text-black;
      padding: 0 0.3em;
      margin-left: 0.8em;
      border-left: 1px solid $input-border;
      cursor: pointer;
    }
  }

}
