@import '../generic/show/general.scss';

.quote-revise {
  @include general;

  .quote-details-summary {
    margin-left: 2em;
    margin-right: 2em;
  }

  .toggle-list {
    margin: 0 1em 1em 1em;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

}
