@import '../../../root/globals.scss';
@import '../../../generic/show/nested-tabs.scss';

div.notes-container {

  @include nested-tabs;

  margin: 2em;
  .accordion { margin-top: 1em; }

  div.details-options {
    display: flex;
    min-height: 1.75em;
    margin-top: 1em;

    div {
      display: flex;
      color: $primary-color;
      align-items: center;
      font-size: 13px;
      padding: 1.25em 2em 0 2em;

      span {
        cursor: pointer;
        padding-right: 1em;
      }

      label {
        padding-right: 0.5em;
      }
    }

    div:last-of-type {
      color: $error;
    }
  }

  div.item-details {
    div.details-options {
      margin: 0;
      border-top: 0;

      div { padding: 0 2em; }
    }
  }

  .note-delete-modal {
    span.name {
      padding-bottom: 1em;
    }

    div {
      margin: 1em 0;
    }

    div.close-modal {
      top: -2.8em
    }

    div.modal-content {
      margin: 0;

      button.delete-button {
        margin-top: 0.8em;
      }
    }
  }

}
