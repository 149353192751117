@import '../root/globals.scss';
@import '../generic/Pane.scss';

.quote-rates.form {

  .quote-rate-select {
    .option {
      display: flex;
      justify-content: space-between;

      .rate {
        text-align: right;
        margin-right: 1em;
        min-width: 2em;
      }
    }

    .qrs__single-value {
      width: 100%;
    }

    .select__single-value {
      width: 100%;
    }
  }

  .rate {
    min-width: 8em;
  }

  .total-pane {
    @include pane;
    text-align: right;
    padding: 2.5em 2em 2.5em 0;
    h3 {
      margin: 0;
      padding: 0;

      span {
        color: $primary-color;
      }
    }
  }

  .three-col {
    margin-bottom: 2em;
    padding-bottom: 1em;
    border-bottom: 1px solid  #F8F9FD;
  }

  .advanced-breaker {
    margin: 1.5em 0 1.5em 0;
    height: 1px;
    background: #F8F9FD;
    width: 100%;
  }
}
