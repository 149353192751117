@import '../root/globals.scss';

div.recipient-grid {
  width: 100%;
  min-height: 50px;
  margin: 0.3em 0;
  display:grid;
  grid-template-columns: 20px auto;

  span {
    margin: 1em 0;
    color: $light-grey;
  }

  div.select {
    input { position: static; }

    div:first-of-type { line-height: 1.6rem; }
    div.select__control {
      border: none;
      height: 100%;
    }

    div.select__multi-value {
      background: lighten($primary-color, 45%);
      height: 30px;
    }
  }
}

.dropdown-button {
  .email-options {
    position: absolute;
    background-color: inherit;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
}
