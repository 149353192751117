@import '../root/globals.scss';

.quote-new {
  .quote-rates-page {
    @media (min-width: $x-large) {
      display: flex;

      .quotes-history-container {
        width: 25em;
        min-width: 25em;
        margin-left: 3em;
        margin-right: 1em;
      }

      .quotes-history {
        position: fixed;

        width: 25em;
        min-width: 25em;

        .list { margin-top: 1.5em; }
      }
    }

    @media (max-width: $x-large) {
      .quotes-history {
        margin-top: 1em;
        max-width: $medium; /* match form width */

        .list {
          display: flex;
          flex-wrap: wrap;
          margin-top: 1.5em;
        }
      }
    }

  }

  .quote-rates {
    max-width: $medium;
  }

}
