@import '../root/globals.scss';

td .dropdown-button .dropdown-content {
  top: 107%;
  left: 0;
  right: auto;
}

.dropdown-button {
  position: relative;
  display: inline-block;

  i {
    padding-left: 0.5em;
  }

  &.dropup .dropdown-content {
    top: auto;
    bottom: 95%;
    left: 0;
    right: auto;
  }

  .dropdown-content {
    font-family: $font;
    position: absolute;
    right: 0;
    background-color: $white;
    min-width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border: 1px solid $ghost-white;
    border-radius: 4px;
    top: 95%;
    z-index: 1;

    list-style-type: none;
    white-space: nowrap;

    li.danger {
      color: $danger;

      &:hover {
        background: $danger;
        color: $white;
      }
    }

    li.disabled {
      cursor: default;

      &:hover {
        background-color: $white;
      }
    }

    li {
      padding: 0.75em;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-size: 0.96em;

      &:hover {
        background-color: $menu-highlight;
      }

      svg {
        min-width: 1.3em;
        margin-right: 1em;
        cursor: inherit;
      }

      label {
        cursor: inherit;
      }
    }
  }
}
