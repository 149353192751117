@import '../root/globals.scss';

div.growing-field-list {

  @mixin child-with-options {
    position: relative;

    div.growing-field-list-options {
      position: absolute;
      top: 1.73em;
      right: 1em;
      display: flex;
      justify-content: space-evenly;

      button {

        flex: 1;
        margin: 0;
        padding: 1em;
        width: 3.3rem;
        height: 3.3rem;
        box-shadow: none;

        &.plus-button {
          background-color: $primary-color;
          border-radius: 0 5px 5px 0;

          &:hover { background: darken($primary-color, 10%); }

        }

        &.minus-button {
          background-color: $error;
          border-radius: 0;

          &:hover { background: darken($error, 10%); }

        }

        svg { padding-right: 0; }

      }

    }

    @supports (-moz-appearance:none) {

        div.growing-field-list-options {
          top: 1.8em;
            button { padding: 1.03em 1em; }
        }
      }

  }

  div.child-with-an-option {
    @include child-with-options;

    button.minus-button { border-radius: 0 5px 5px 0 !important; }

    div.field {
      margin-right: 3.95em;
      input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        height: 3.25rem;
        padding-right: 1em;
        box-sizing: border-box;
      }
    }
  }

  div.child-with-two-options {
    @include child-with-options;

    div.field {
      margin-right: 6.65em;
      input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        height: 3.25rem;
        padding-right: 1em;
        box-sizing: border-box;
      }
    }
  }

}
