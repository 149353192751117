@import '../../root/globals.scss';
@import '../../generic/show/sidebar.scss';

.company-sidebar {
  @include sidebar;

  .financial {
    .info {
      display: flex;
      justify-content: space-between;
    }
  }

  .customer-details {
    .info.address {
      white-space: pre-wrap;
      word-break: normal;
    }
  }

  .contact-info {
    border-bottom: 1px dotted $light-grey;
    padding-bottom: .8em;
  }

  .contact-info {
    .info {
      display: grid;

      label { margin-bottom: 0.5em; }

      span { line-height: 160%; }
    }
  }
}
