@import '../root/globals.scss';

.topbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: $topbar-height;
  border-bottom: 1px solid $light-border;
  color: $grey;
  background-color: $white;
  position: relative;

  font-family: $font;

  div {
    height: $topbar-height;
  }

  @media (max-width: $large) {
    .topbar-create-new {
      .create-new-clickable {
        display: none;
      }
    }
  }
  @media (max-width: $large) {
    .topbar-user-name {
      display: none;
    }
  }
}

.topbar-item {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

.topbar-navbar-toggle {
  flex-basis: $topbar-height;
  color: $black;
  box-sizing: border-box;
  cursor: pointer;
}

.topbar-logo-container {
  flex-basis: calc(#{$navbar-expanded-width} - #{$navbar-collapsed-width});
  box-sizing: border-box;
  border-right: 1px solid $light-border;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.topbar-logo {
  width: 150px;
  margin-left: -25px;
}

.topbar-notifications {
  color: $grey;
  padding: 0 1.5em;
  transition: all 0.3s;
  box-sizing: border-box;
  border-left: 1px solid $light-border;
  cursor: pointer;
  position: relative;

    .topbar-notifications-icon svg {
      width: 24px;
      height: 24px;
    }

    .notifications-counter {
      background: $error;
      color: $white;
      text-align: center;
      border-radius: 100%;
      font-size: 10px;
      padding: 0 6px;
      line-height: 18px;
      position: absolute;
      top: 15px;
      right: 19px;
      border: 1px solid $white;
    }

  &:hover {
    color: $black;
  }
}

.topbar-user {
  color: $grey;
  padding: 0 1.5em;
  transition: all 0.3s;
  box-sizing: border-box;
  border-left: 1px solid $light-border;
  cursor: pointer;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  float: right;

  span.topbar-user-icon {
    border-radius: 100%;
    width: 42px;
    height: 42px;
    background: #f0f0f0;
    position: relative;
    overflow: hidden;

    .user-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: auto;
      text-align: center;

      img {
        display: block;
        width: 100%;
      }
    }
  }

  span.topbar-user-name {
    margin-left: 1em;
  }

  &:hover {
    color: $black;
  }
}

.topbar-logout {
  flex-basis: $topbar-height;
  font-size: 18px;
  transition: all 0.3s;
  color: $grey;
  box-sizing: border-box;
  border-left: 1px solid $light-border;
  cursor: pointer;

  &:hover {
    color: $black;
  }
}
