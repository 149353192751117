@import '../../root/globals.scss';
@import '../../generic/Pane.scss';
@import '../../forms/input.scss';

.new-company-container {
  font-family: $font;

  h1 {
    color: $primary-color;
  }

  div.status-container {
    div.form-status, div.form-status-error {
      margin: 0 0 2em 0;
    }
  }

  .pane-container {

    .split {
      display: flex;
      flex-direction: row;
      flex-grow: 1;

      div.left-panes {
        flex: 1;
        display: flex;
        flex-direction: column;

        margin-right: 1em;
      }

      .details-pane {
        flex: 1;
        margin-bottom: 1em;
        @include pane;

        .field { padding-top: 0.6em; }
      }

      .contact-pane {
        flex: 1;
        margin-bottom: 1em;
        @include pane;

        div.contact-form {
          padding-top: 0.6em;
        }
      }

      .address-pane {
        flex: 1;
        @include pane;
        margin-bottom: 1em;

        div.address-form {
          padding-top: 0.6em;
        }
      }
    }

    .warehouse-pane {
      flex: 1;
      margin-bottom: 1em;
      @include pane;

      .warehouse-form {
        padding-top: 0.6em;

        div.double-input:first-of-type {
          margin-bottom: 2em;
          padding-bottom: 0.6em;
          border-bottom: 1px solid #F8F9FD;
        }
      }

      div.field input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    div.field {
      input {
        @include input;
        width: 100%;
        text-indent: 1em;
      }
    }
  }

  button.submit {
    margin-right: 0;
    float: right;
    white-space: nowrap;
  }

}
