@import '../root/globals.scss';

@mixin form-status {
  margin: 1em 1em 2em 1em;
  padding: 1.2em;
  border-radius: 5px;
  text-align: center;
  vertical-align: middle;
  color: $white;
  font-size: 1em;

  &:empty {
    display: none;
  }
}

div.form-status {
  @include form-status;
  background-color: $success;
}

div.form-status.error {
  @include form-status;
  background-color: $error;

  div {
    margin-bottom: 0.5em;

    &:last-of-type { margin-bottom: 0; }
  }
}
