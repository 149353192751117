@import '../root/globals.scss';

.line-items-billing.generic-show {

  .form .section {
    @media (max-width: $large) {
      overflow-x: auto; /* overflow causes problems for select dropdown position */
    }

    box-shadow: none;
    border-radius: 0;
    padding: 1em 2em;

    .header {
      margin: 0 1em 1em 0;
      h3 { font-size: 1.2rem; }
      .header-rule { margin: 0 -2em 1em -2em; }
    }

    .fields {
      max-width: 100%;
    }
  }

  .form .section .fields .tabular {
    display: table;
    width: 100%;

    @media (max-width: $large) {
    /* overflow causes problems for select dropdown, ensure there is enough room  */
      padding-bottom: 16em;
    }

    .headers {
      .col {
        padding-left: 0.2em;
      }
    }

    .line-item.condemned {
      input, .company-select__single-value, .taxRate__single-value {
        text-decoration: line-through;
      }
    }

    .line-item {
      .col {
      }
    }

    .headers, .line-item {
      display: table-row;

      .col {
        display: table-cell;
        padding-right: 1em;
        padding-bottom: 0.5em;

        .sff-input-group {
          display: table-row;
          &.prefix .input-group-addon.prefix { display: table-cell; }
          input {
            margin-left: -2px;
            border-radius: 0 4px 4px 0;
          }
        }

        &.chargeCode {
          min-width: 8em;
          max-width: 12em;
        }
        &.description {
          min-width: 5em;
          max-width: 20em;
        }
        &.companyId {
          min-width: 14em;
          max-width: 20em;
        }
        &.value {
          min-width: 7em;
          max-width: 9em;
        }
        &.taxRate {
          min-width: 8em;
          max-width: 12em;
          padding-right: 1em;
        }
        &.confirmed {
          min-width: 4em;
          max-width: 4em;
          text-align: center;
        }
        &.destroy {
          min-width: 2em;
          max-width: 2em;
          padding-right: 1em;
        }

        &.destroy button {
          width: 2.85rem;
          height: 2.85rem;
          padding: 0.2em;
          margin: 0;
        }

        .money-input {
          padding-right: 5px;
        }

        input {
          width: 100%;
          min-width: 100%;

          height: 2.5rem;
          font-size: 0.9rem;
          font-weight: 300;
          font-family: "Ubuntu", sans-serif;
          border-color: hsl(0,0%,80%);
          border-radius: 4px;
          border-style: solid;
          border-width: 1px;
          color: hsl(0,0%,20%);
          margin-left: 2px;
          margin-right: 2px;
          padding: 2px 0;
          text-indent: 1em;

          &:disabled {
            background: #f2f2f3;
            border-color: #e6e6e6;

            &::-webkit-input-placeholder {
              color: hsl(0,0%,60%);
              opacity: 1;
            }
            &::-moz-input-placeholder {
              color: hsl(0,0%,60%);
              opacity: 1;
            }
            &::-ms-input-placeholder {
              color: hsl(0,0%,60%);
              opacity: 1;
            }
            &::placeholder {
              color: hsl(0,0%,60%);
              opacity: 1;
            }
          }
        }

        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type="number"] {
          -moz-appearance: textfield;
        }

        .select {
          >div:first-of-type {
            height: 2.85rem;
            line-height: 2.85rem;
            top: 0.4rem;
          }
        }

      }
    }
  }

  .section.controls {
    display: flex;
    margin-bottom: 0;
  }

  .controls {
    display: flex;

    button.submit {
      background-color: $success;
    }
  }
}
