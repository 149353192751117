@import '../../root/globals.scss';

div.default-supplier-hub-options {
  width: 100%;
  display: flex;
  flex-direction: row;

  button.option-edit-toggle {
    flex-basis: 4em;
    height: 3.3rem;
    margin-left: 0em;
    margin-top: 1.9em;
    padding: 0.5em;
  }
}
