@import '../root/globals.scss';

@mixin input($transition-time: 0.3s) {
    font-family: inherit;
    border-radius: 5px;
    transition: border-color $transition-time ease-in-out;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    font-weight: 300;
    width: 100%;
    font-size: 0.9rem;
    box-sizing: border-box;
    padding-right: 1em;
  
    height: 3.3rem;
    text-indent: 1em;
  
    &::-webkit-input-placeholder {
      color: $input-border;
      opacity: 1;
    }
    &::-moz-input-placeholder {
      color: $input-border;
      opacity: 1;
    }
    &::-ms-input-placeholder {
      color: $input-border;
      opacity: 1;
    }
    &::placeholder {
      color: $input-border;
      opacity: 1;
    }
  }
  