@import '../../root/globals.scss';

div.block-options {
  height: inherit;
  position: relative;

  div.icon-container {
    display: flex;
    align-items: center;
    height: inherit;
  }

  div.block-options-drop-menu {
    position: absolute;
    right: -0.26em;
    top: 2.7em;
    z-index: 1;

    min-width: 9em;
    background-color: $white;

    box-sizing: border-box;
    border: 1px solid $input-border;
    border-radius: 1px;

    box-shadow: 0 5px 6px rgba(0,0,0,0.08);

    .block-option {
      white-space: nowrap;
      word-break: none;

      padding: 0.5em;
    }
  }

}
