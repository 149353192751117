@import '../root/globals.scss';

.layout-under-topbar {
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - #{$topbar-height});
  height: calc(100% - #{$topbar-height});
  position: relative;
  z-index: 5;

  h1 {
    margin-bottom: 1.2em;
    color: $primary-color;
    font-family: $font;
  }
}

.layout-barless-page {
  flex: 1;
}

.layout-content-pane {
  flex: 1;
  background-color: $ghost-white;
  padding: 1em 2em 1em 2em;
  overflow-x: hidden;

  .index-controls {
    margin-top: 1em;
    display: flex;
    justify-content: space-between;

    button:last-of-type { margin-right: 0; }
  }
}

h3 {
  font-size: 1.35rem;
}

h4 {
  font-size: 1.2rem;
  color: $black;
}

.hidden {
  display: none;
}
