@import '../root/globals.scss';

.accordion { margin-top: 1em; }

  div.email-details-options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 1.75em;
    border-top: 1px solid $input-border;
    margin-top: 1em;

    div {
      display: flex;
      color: $primary-color;
      align-items: center;
      font-size: 13px;
      padding: 1.25em 2em 0 2em;

      span {
        cursor: pointer;
        padding-right: 1em;
      }

      a {
        text-decoration: none;
        outline: 0;
        color: $primary-color;
        cursor: pointer;
      }

      label {
        padding-right: 0.5em;
        cursor: pointer;
      }
    }

    div:last-of-type {
      color: $error;

      a { color: $error; }
    }

    div.send {
      color: $primary-color;
    }

    div.destroy {
      color: $error;
    }
  }

div.item-details { padding: 1em 0; }

div.details-email {
  padding: 0.5em 1em 1em 1em;

  h4 {
    margin: 0.3em 0 0.3em 0;
  }
}

div.edit-email-container {
  .react-tabs {
    -webkit-tap-highlight-color: transparent;

    &__tab-list {
      background-color: $white;
      margin: 0 0 2em;
      padding: 0;

      border-bottom: 1px solid $light-grey;
    }

    &__tab {
      padding: 0 1em 1em 1em;
      bottom: -1px;

      &--selected, &--selected:hover {
        color: $primary-color;
        border-bottom: 4px solid $primary-color;
      }

      &:hover {
        background: $white;
        border-bottom: 4px solid $primary-color;
      }

      &--disabled {
        visibility: hidden;
        padding: 0;
        transition: none;
      }
    }
  }
}

.email-delete-modal {
  span.name {
    padding-bottom: 1em;
  }

  div {
    margin: 1em 0 1em 0;
  }

  div.close-modal {
    top: -2.8em
  }

  div.modal-content {
    margin: 0;

    button.delete-button {
      margin-top: 0.8em;
    }
  }
}

pre {
  font-family: inherit;
  white-space: pre-wrap;
}

span.email-item {
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
  "subject date"
  "recipient .";

  span.item-recipient {
    grid-area: recipient;
    font-size: 0.8em;
  }

  span.item-date {
    grid-area: date;
    font-size: 0.8em;
    margin: 0 2em 0 auto;
  }

}
