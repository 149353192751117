@import '../../../root/globals.scss';


.drag-and-drop-job {
  color: $black;

  div.drag-and-drop-job-details {
    display: flex;
    flex-direction: column;

    div.job-detail {
      display: flex;
      flex-direction: row;
      padding: 0.2em;

      label {
        flex: 0.5;

        white-space: nowrap;
        font-weight: 600;

        margin-right: 1em;
      }

      span {
        flex: 1;
      }

      a {
        flex: 1;
        text-decoration: none;
        color: $success;
      }
    }
  }

  span.job-title {
    color: $black;
    width: 100%;
    position: relative;
  }

  span.title-string {
    display: inline-block;
    width: calc(100% - 6em);
  }

  span.status-indicator {
    position: absolute;
    top: -0.2em;
    right: 0;
    font-size: 0.8rem;
    color: $white;
    padding: 0.3em 0.6em 0.3em 0.6em;
    border-radius: 15px;
    margin: 0 1em 0 1em;

    &.active {
      background-color: $success;
      color: $white;
    }

    &.pending {
      background-color: $grey;
    }

    &.finalised {
      background-color: $primary-color;
    }

    &.cancelled {
      background-color: $error;
    }
  }
}

