@import '../../root/globals.scss';

@mixin controls {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.5em;

  .left {
    display: flex;

    button {
      margin-left: 0;
    }
  }

  .right {
    display: flex;
    align-items: center;

    button {
      margin-right: 0;
    }
  }
}
