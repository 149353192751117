@import '../root/globals.scss';

.line-item-summary {
  display: flex;
  width: 100%;
  margin-top: 1em;

  button {
    margin-left: auto;
    background-color: $success;
  }
}
