@import '../../root/globals.scss';
@import '../../forms/select/select.scss';

@mixin selectMixin {
  div.select {
    @include select-mixin;
  }

  div.select__control {
    @include select__control-mixin;
  }

  div.select__option {
    @include select__option-mixin;
  }
}

.index-table {
  overflow-x: auto;
  display: grid;

  .pagingRow, .controlRow {
    max-width: 85vw;
  }

  .header, ,.contents {
    span {
      min-width: 7em;
    }
  }
}

div.table {
  font-size: 15px;

  div.controlRow {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .centre {
      display: flex;
      align-items: center;
    }

    button.columns {
      padding: 0;
      font-size: 1.4em;
      padding: 0;
      margin: 0;
      border-radius: 0;
      color: $black;
      box-shadow: none;
      background: none;
      svg path {
        fill: $black;
      }
    }

    div.leftSide {
      div.search {
        position: relative;

        svg {
          margin: auto;
          color: $grey;
          position: absolute;
          top: 14px;
          left: 14px;
        }

        input {
          padding: 1em;
          border: 1px solid $input-border;
          border-radius: 0.4em;
          text-indent: 30px;
          width: 250px;

          &:focus {
            border-color: $success;
            transition: border-color 0.3s ease-in-out;
            box-shadow: none;
            outline: none;
          }

        }
      }
    }

    div.centre {

      > div {
        display: flex;

        > label, > span {
          margin: auto 1em;
        }

        > div.sortSelect {
          @include selectMixin;
          width: 180px;
          font-weight: 700;
          text-transform: capitalize;

          > div {
            cursor: pointer;
            min-height: 43px;

            > div {
              color: $black;

              > span {
                width: 0;
              }

              > div {
              color: $black;
              }
            }
          }
        }

        .sortDirection {
          font-size: 1.4em;
          cursor: pointer;
          padding: 0 0 0 1em;
          color: $black;
          border-left: 1px solid lighten($black, 65%);
        }
      }

    }

    div.rightSide {
      display: flex;

      > div {
        display: flex;
        white-space: inherit;

        &:first-of-type { margin-right: 1em; }

        > label {
          margin: auto 1em;
        }

        > div {
          font-weight: bold;

          > div {
            min-height: 43px;
          }
        }

        > div.pageSelect {
          @include selectMixin;
          min-width: 80px;

          div {
            cursor: pointer;
          }
        }

        > div.categorySelect {
          @include selectMixin;
          min-width: 150px;

          div {
            cursor: pointer;
          }
        }
      }
    }
  }

  hr {
    opacity: 0.2;
    margin: 1.4em 0 1.8em 0;
  }

  div.header {
    display: flex;
    width: auto;
    margin: 1em 0;
    padding: 0 1em;
    span {
      flex: 1 0 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-transform: uppercase;
      font-weight: bold;
      padding-right: 2em;
    }

    span.flex-1 {
      flex: 1 0 0;
    }

    span.flex-2 {
      flex: 2 0 0;
    }

    span.flex-3 {
      flex: 3 0 0;
    }

    span.flex-4 {
      flex: 4 0 0;
    }

    span.flex-5 {
      flex: 5 0 0;
    }
  }

  div.contents {
    div.row {
      display: flex;
      width: auto;
      padding: 1em;
      border-radius: 0.5em;
      background: $white;
      color: $black;
      margin: 0.335em 0;
      box-shadow: 0 0.1em rgba(0, 0, 0, 0.1);
      transition: background ease-in-out 0.3s;

      .navbar-item-link {
        cursor: pointer;
      }

      &:hover { background: lighten($menu-highlight, 3%); }

      svg {
        margin-left: 4em;
      }

      span {
        flex: 1 0 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 2em;

        a {
          color: $primary-color;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      span.flex-1 {
        flex: 1 0 0;
      }

      span.flex-2 {
        flex: 2 0 0;
      }

      span.flex-3 {
        flex: 3 0 0;
      }

      span.flex-4 {
        flex: 4 0 0;
      }

      span.flex-5 {
        flex: 5 0 0;
      }
    }
  }

  div.pagingRow {
    margin: 2em 0;
    display: flex;

    span.info {
      font-size: 10pt;
      margin: auto 0;
      color: $grey;
    }

    span.control {
      margin-left: auto;
      white-space: nowrap;

      svg {
        margin: 0 0.6em;
        cursor: pointer;
      }

      span {
        border-radius: 0.3em;
        padding: 0.3em 0.6em;
        margin: 0 0.2em;
      }

      span.page {
        background: $primary-color;
        color: $white;
      }

      span.page-button {
        background: $white;
        cursor: pointer;
      }
    }
  }
}
