@import '../../../root/globals.scss';


.jobs-drop-column {
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
    
  .drop-column-title {
    margin: 0;
  }
  
  hr.rule {
    width: 100%;
    border: none;
    height: 1px;
    background-color: $input-border;
  }

  margin: 0 1em 0 1em;
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }

  div.dropzone {
    margin-top: 1em;
    margin-bottom: 1em;

    display: flex;
    flex-direction: column;
  }

  div.dropzone-instructions {
    display: flex;
    margin-top: 0.5em;

    border-radius: 5px;
    background-color: $success;
    color: $white;
    padding: 1em;
    span {
      margin-left: 0.5em;
    }
  }
}

