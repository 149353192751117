@import '../../root/globals.scss';

div.warehouse-form {
  input:disabled {
    background: $ghost-white;
  }

  div.warehouse-requirements {
    margin-top: 1em;
  }

  div.requirement {
    min-width: 220px;

    margin: 0 1em 1em 1em;
    padding-bottom: 0.3em;
    border-bottom: 1px solid $faded-white;

    label {
      display: inline-block;
    }

    div.react-toggle {
      float: right;
    }

    input { margin-bottom: 1em; }
  }
}
