@import '../../../root/globals.scss';

div.note-form {

  div.item-name {
    font-weight: 1000;

    span.icon {
      background: red;
    }
  }

  textarea {
    min-height: 200px;
    resize: none;
  }

}

