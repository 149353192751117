@import '../root/globals.scss';

.checkbox {
  display: inline-flex;
  padding: 0.5em 0;
  background: white;
  border: 1px solid $text-black;
  border-radius: 0.2em;
  position: relative;
  cursor: pointer;
  align-items: center;
  min-width: 8em;
  margin-top: 1.7em;
  height: 2em;

  &.disabled {
    cursor: default;
  }

  &.checked {
    background: $text-black;

    .label {
      color: $white;

      &:after {
        opacity: 1;
      }
    }
  }

  .label {
    padding: 0 1em;
    color: $text-black;
    white-space: nowrap;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.3s ease-in-out;
    box-sizing: border-box;

    &:after {
      content: '';
      width: 1em;
      height: 0.5em;
      position: absolute;
      right: 1em;
      border: 0.2em solid #fcfff4;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg);
      transition: all 0.3s ease-in-out;
    }
  }

  &.checked span.label {
    left: 0;
    transform: translateX(0);
    width: 100%;
  }

  input {
    padding-left: 1em;
  }

  input[type=checkbox] {
    visibility: hidden;
  }
}
