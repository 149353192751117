@import '../root/globals.scss';

div.editor {
  min-height: 200px;
  max-height: 400px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 1em 0;
  border-top: 1px solid lighten($light-grey, 15%);
}

div.editor-toolbar {
  border-top: 1px solid lighten($light-grey, 15%);
  border-bottom: 1px solid lighten($light-grey, 15%);
  margin: 0 1em;

  button { path { width: 85%; height: 50%; } }

  .editor-button {
    background: none;
    box-shadow: none;
    width: 30px;
    padding: 8px;
    margin: 5px;

    svg {
      path {fill: $black;}
      &:hover path { fill: $primary-color; }
    }

    &.active path { fill: $primary-color; }
  }
}
