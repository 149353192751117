@import '../theme/eurobridge-color-palette.scss';
$font: 'Ubuntu', sans-serif;

button {
  font-family: $font;
  margin: 1em;
  padding: 0.75em 1.5em 0.75em 1.5em;
  border: 0px;
  border-radius: 5px;
  outline: none;
  box-shadow: 0 3px 6px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: $white;
  font-size: 0.9rem;

  &.plus-button { font-size: 1rem; }

  &:hover:enabled {
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.5);
  }

  &:focus {
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.5);
  }

  .icon {
    padding-right: 0.75em;
    color: $white;
  }
}

button.primary {
  background-color: $primary-color;
}

button.secondary {
  background-color: $black;
}

button.success {
  background-color: $success;
}

button.danger {
  background-color: $danger;
}

button:disabled {
  opacity: .5;
  cursor: not-allowed;
}
