.modal-window.post-invoice-modal {
  .modal-content {
    .options {
      justify-content: space-between;
    }
    .form-status {
      white-space: pre-wrap;
    }
  }
}

