@import '../../root/globals.scss';

.job-priority-switch {
  display: flex;
  flex-direction: row;
  font-family: $font;

  span.label {
    display: flex;
    align-items: center;
    font-weight: 500;
    padding-right: 0.75em;
  }

  div {
    display: flex;
    justify-content: center;
    min-width: 8em;
    padding: 0.55em;
    box-sizing: border-box;
    border: solid 1px $black;

    cursor: pointer;

    transition: all 0.3s ease-in-out;

    &:not(.end) {
      border-right: transparent;
    }

    &.start {
      border-radius: 5px 0 0 5px;
    }

    &.end {
      border-radius: 0 5px 5px 0;
    }

    &.selected {
      background-color: $primary-color;
      color: $white;
    }
  }
}
