@import '../../root/globals.scss';

@mixin nested-tabs {
  .react-tabs {
    -webkit-tap-highlight-color: transparent;

    &__tab-list {
      background-color: $white;
      margin: 0 0 2em;
      padding: 0;

      border-bottom: 1px solid $light-grey;
      border-top: 1px solid $light-grey;
    }

    &__tab {
      padding: 1.5em;
      bottom: -1px;

      &--selected, &--selected:hover {
        color: $primary-color;
        border-bottom: 4px solid $primary-color;
      }

      &:hover {
        background: $white;
        border-bottom: 4px solid $primary-color;
      }

      &--disabled {
        visibility: hidden;
        padding: 0;
        transition: none;
      }
    }
  }
}