@import '../root/globals.scss';
@import './input.scss';

$transition-time: 0.3s;

.field {
  margin: 0 1em 1em 1em;

  label {
    transition: color $transition-time ease-in-out;
    display: block;
    padding-bottom: 0.8em;
    color: $text-black;
    font-size: 0.9rem;
  }

  span {
    svg.svg-inline--fa {
      transition: color $transition-time ease-in-out;
    }
  }

  input {
    @include input($transition-time: $transition-time);
    border: 1px solid $input-border;

    &:disabled {
      background: #f2f2f3;
      border-color: #e6e6e6;

      &::-webkit-input-placeholder {
        color: hsl(0,0%,60%);
        opacity: 1;
      }
      &::-moz-input-placeholder {
        color: hsl(0,0%,60%);
        opacity: 1;
      }
      &::-ms-input-placeholder {
        color: hsl(0,0%,60%);
        opacity: 1;
      }
      &::placeholder {
        color: hsl(0,0%,60%);
        opacity: 1;
      }
    }
  }

  textarea {
    @include input($transition-time: $transition-time);
    border: 1px solid $input-border;
    text-indent: 0;
    padding: 1em;
  }

}

.field-focus-style {

  &:focus-within {
    label {
      color: $success;
    }

    span {
      svg.svg-inline--fa {
        color: $success;
      }
    }

    span:after {
      border-color: $success;
      transition: border-color $transition-time ease-in-out;
    }

    input, textarea {
      border-color: $success;
      transition: border-color $transition-time ease-in-out;
    }
  }
}

.field-error {
  label {
    color: $error;
  }

  span {
    svg.svg-inline--fa {
      color: $error;
    }
  }

  span:after {
    border-color: $error;
    transition: border-color $transition-time ease-in-out;
  }

  input {
    border-color: $error;
    transition: border-color $transition-time ease-in-out;
  }
}

label:empty { display: none; }

div.double-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 0.5em);

  div.field {
    width: 46%;
  }

  > div:first-of-type {
    margin-right: 0.5em;
  }

  > div:last-of-type {
    margin-right: 0.5em;
    margin-left: 0em;
  }
}

.expand-section {
  .header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    svg {
      margin-top: 0.5em;
      margin-right: 2em;
      transition: all 0.3s ease-in-out;
    }
  }
  .expanded {
    cursor: pointer;
    height: 2em;
    top: 1em;

    svg {
      margin-top: 0.25em;
      transition: all 0.3s ease-in-out;
    }
  }

  &.collapsed.section {
    padding-bottom: 0 !important;
    .header {
      padding-bottom: 0;
      margin-bottom: 0 !important;
    }
    .header-rule { margin-bottom: 0 !important; }
    .fields { display: none !important; }
  }

}
