@import '../../root/globals.scss';

.tariff-surcharges-form {
  .divider {
    border-bottom: 1px solid $input-border;
    width: 100%;
    margin: 2em;
  }

  .checkbox {
    width: 10em;
  }

  .toggles {
    width: 100%;
    margin-left: 1em;
    margin-right: 1em;
    display: flex;
    justify-content: space-between;
  }
}
