@import '../../root/globals.scss';
@import '../../generic/show/general.scss';


.consignment-tab {
  @include general;

  .section {
    margin-bottom: 2em;
    padding-bottom: 2em;
    border-bottom: 1px solid #F8F9FD;

    &.controls { border-bottom: 0; }
  }

  div.consignment-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1.5em;

  }

  div.rule {
    margin: 1em 1em 1.5em 1em;
    border-bottom: 1px dotted $light-grey;
  }

  div.inline-toggle-with-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 9em;

    margin: 0 1em 1em 1em;

    label {
      white-space: nowrap;
      width: 160px;
    }

    div.react-toggle {
      margin-left: 1em;
    }
  }

  div.form-fields {
    display: flex;
    flex-direction: row;

    div.field {
      flex: 1;
    }
  }

}
