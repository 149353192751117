@import '../../root/globals.scss';
@import '../../generic/Pane.scss';
@import '../../forms/input.scss';

.new-job-container {
  font-family: $font;

  h1 {
    color: $primary-color;
  }

  div.status-container {
    div.form-status, div.form-status-error {
      margin: 0 0 2em 0;
    }
  }

  .pane-container {

    .pane {
      flex: 1;
      margin-bottom: 1em;
      @include pane;
    }

    div.field {
      input {
        @include input;
        width: 100%;
        text-indent: 1em;
      }
    }
  }

  button.submit {
    margin-right: 0;
    float: right;
    white-space: nowrap;
  }

}
