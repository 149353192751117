@import '../../../root/globals.scss';
@import '../../../generic/show/general.scss';


.jobs-on-a-groupage-tab {
  @include general;

  div.form-status {
    margin: 2em 2em 0 2em;
  }

  div.job-columns-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin: 1em 2em 1em 2em;
  }
}

