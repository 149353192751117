@import '../root/globals.scss';

.topbar-create-new {
  color: $grey;
  margin-left: auto;
  float: right;
  transition: all 0.3s;
  box-sizing: border-box;
  border-left: 1px solid $light-border;
  cursor: pointer;
  position: relative;
  z-index: 10;

  div.create-new-clickable {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1.5em;
    position: relative;
    z-index: 10;
    background: white;
  }

  span.topbar-create-new-icon {
    padding-right: 0.4em;
  }

  &:hover {
    color: $black;
  }

  &.open {
    color: $black;
  }
}

.topbar-create-new-dropdown {
  position: absolute;
  top: -100%;
  background-color: $white;
  width: calc(100% + 2px);
  height: auto !important;
  box-shadow: 0 5px 6px rgba(0,0,0,0.08);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: -1;
  pointer-events: none;

  &.open {
    top: $topbar-height;
    opacity: 1;
    pointer-events: all;
    z-index: 5;
  }

  a {
    text-decoration: none;

    &:first-of-type {
      div.new-option {
        border-top: 1px solid $light-border;
      }
    }
  }

  div.new-option {
    display: flex;
    align-items: center;
    background-color: $white;
    color: $grey;
    height: 60px;
    border: 1px solid $light-border;
    border-top: 0;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $black;
    }

    .create-new-icon {
      padding-right: 0.7em;
      margin-left: calc(1.5em + 2px);
    }

  }

}
