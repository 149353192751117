@import '../../root/globals.scss';

@mixin general {
  font-size: 0.9rem;

  h4 {
    margin: 1em 1em 1em 0.86em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    width: 100%;
  }

  .section, .toggles-section {
    margin: 1em 0;
    padding: 0 1em;
  }

  .section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .field {
      width: 46%;
      min-width: 20em;
    }
  }

  .controls {
    display: flex;
    // border-top: 1px dotted $light-grey;
    margin: 0 2em 0 2em;
    padding: 0;
    justify-content: flex-end;

    button.submit {
      margin-left: auto;
      margin-right: 0;
      background-color: $success;
    }
  }

  .details {
    padding-bottom: 1.5em;
    border-bottom: 1px solid #F8F9FD;

    .fields {
      display: flex;
      flex-wrap: wrap;

      .field {
      flex: 1;
      margin-right: 3em;

      input {
        border-radius: 0.3em;

        @media (min-width: $large) {
          /*width: 50%;*/
        }

        @media (max-width: $large) {
          width: 100%;
        }
      }
    }

    }
  }

  .toggles {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 1em;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid $text-black;

    .field {
      margin: 0;
      flex: 1;

      &:last-of-type .checkbox, &:last-of-type .checkbox.checked { border-right: 0; }

      .checkbox {
        display: flex;
        align-items: center;
        height: auto;
        min-width: 10em;
        border-radius: 0;
        margin-top: 0;
        padding: 1.5em 0;
        border: 0;
        border-right: 1px solid $text-black;
        position: relative;
        transition: all 0.3s ease-in-out;

        span.label {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          transition: all 0.3s ease-in-out;
          padding: 0 1em;
          display: inline-block;
          box-sizing: border-box;
        }

        &.checked { border-right: 1px solid white; }

        &.checked span.label {
          left: 0;
          transform: translateX(0);
          width: 100%;
        }

        input {
          padding-left: 1em;
          height: auto;
        }
      }

      .react-toggle--disabled {
        cursor: default;
      }
    }
  }
}
