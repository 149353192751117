@import '../root/globals.scss';
@import '../theme/eurobridge-color-palette.scss';

.xero-contact-details {
  background: lighten($primary-color, 50%);
  border: 2px solid $primary-color;
  margin: 1em;
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
  padding: 0.75em;

  .xero-detail {
    padding: 1em;
    width: 9em;
    display: grid;

    label {
      font-weight: 600;
      padding-right: 0.5em;
      padding-bottom: 0.3em;
    }
  }
}

