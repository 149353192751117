@import '../../root/globals.scss';

.job-autoline-modal {
  width: 75%;
  min-width: 22em;

  .summary {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .field {
      min-width: 16em;
      width: 20em;

      input {
        width: 100%;
      }
    }
  }

  .history {
    padding: 1em;
    padding-right: 3em;

    .history-job, .headers {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      border-bottom: 1px solid $gray;
      padding: 0.5em;
    }

    .headers {
      font-weight: 600;
      border-bottom: 1px solid $black;
    }

    span {
      flex: 1;
    }

    .no-history {
      padding: 0.5em;
      font-style: italic;
    }
  }

  .controls {
    padding: 1em;
    display: flex;
    justify-content: space-between;
  }

  .select__single-value {
    width: 100%;
  }

  .distro-option {
    display: flex;
    justify-content: space-between;
    padding-right: 1em;
  }
}
