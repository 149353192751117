@import '../../../root/globals.scss';
@import '../../../generic/show/general.scss';


.invoices {
  @include general;
  margin: 0 1em 0 1em;

  .jobs {
    display: flex;
    flex-direction: column;
    margin: 0 1em 0 1em;
  }

  .jobs .job-row {
    min-height: 2.1em;
    padding: 0.6em 0em;
    margin: 0.25em 0 0.25em 0;
    background-color: $faded-white;
    border-radius: 4px;

    display: flex;
    flex-direction: row;
    align-items: center;

    &.header {
      background-color: $white;
      font-weight: 600;
    }

    div {
      flex-basis: 60px;
      flex-grow: 0;
      font-size: 20px;
      margin-left: 0.5em;
    }

    div.checkmark {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    span {
      flex-basis: 80px;
      flex-grow: 0;

      a {
        text-decoration: none;
        color: $primary-color;

        &:hover {
          text-decoration: underline;
        }
      } 
    }
    
    span.customer-name {
      flex-basis: 180px;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span.invoice-details {
      flex-basis: 120px;
      flex-grow: 1;

      display: flex;
      flex-direction: column;

      span.detail {
        flex-basis: 0;
        color: $error;

        &.posted {
          color: $grey;
          font-style: italic;
        }
      }
    }
  }

  div.button-row {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5em;
    margin-bottom: 2em; 
  } 

  div.status-messages {
    display: flex;
    flex-direction: column;
    
    span {
      margin-bottom: 0.5em;
    }
  }

}


