@import '../../root/globals.scss';

.planner-unit-block {
  .header.color {
    margin-right: 0.5em;
  }
}

.planner-unit-icon {

  display: flex;
  justify-content: center;
  margin-right: 0.5em;
  cursor: pointer;

  svg {
    height: 100%;
    vertical-align: center;
    color: inherit;
    filter: invert(25%) brightness(200%);
  }

  .overlay {
    position: absolute;
    z-index: 98;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: auto;
  }

  .sketch-picker {
    position: absolute;
    z-index: 99;
    cursor: pointer;
  }

}
