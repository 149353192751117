@import '../../root/globals.scss';

div.company-reg-numbers-container {
  margin: 2em;

  div.reg-numbers-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div.field {
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
      width: 23%;
    }
  }

  button.submit {
    margin-left: 0;
    margin-bottom: 0;
    margin-right: auto;
    background-color: $success;
  }
}
