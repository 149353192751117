@import '../../root/globals.scss';

button.company-notifications {
  font-size: 0.8em;
  padding-left: 1em;
  padding-right: 1em;
  background-color: inherit;
  color: $black;
  border: none;
  box-shadow: none;

  svg {
    color: $black;
    padding-right: 1em;
    font-size: 1.2em;
    bottom: -0.15em;
    position: relative;
  }

  i {
    padding-left: 1em;
    font-size: 0.8em;
  }
}
